import { graphql, Link } from "gatsby";
import * as _ from "lodash";
import { setLightness } from "polished";
import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { Footer } from "../components/Footer";
import { Wrapper } from "../components/Wrapper";
import IndexLayout from "../layouts";
import { colors } from "../styles/colors";
import { SiteMain } from "../styles/shared";
import { Ast } from "../components/PostContent";
import {
  inner,
  outer,
  TransitionNavigation,
} from "../components/header/SiteNav";

export interface Author {
  id: string;
  bio: string;
  avatar: {
    children: any[];
  };
}

interface PageTemplateProps {
  data: any;
  pageContext: PageContext;
  location: Location;
}

export interface PageContext {
  excerpt: string;
  timeToRead: number;
  fields: {
    slug: string;
  };
  frontmatter: {
    image: {
      childImageSharp: {
        fluid: any;
      };
    };
    excerpt: string;
    title: string;
    date: string;
    draft?: boolean;
    tags: string[];
    author: Author[];
  };
}

const FeatureTemplate = ({ data }: PageTemplateProps) => {
  return (
    <IndexLayout className="post-template">
      <Wrapper>
        <TransitionNavigation mode="normal" />
        <main id="site-main" className="site-main" css={[SiteMain, outer]}>
          <div css={inner}>
            <div>
              <Link to="/features">View all Features</Link>
            </div>
            <div
              style={{
                backgroundColor: "#fff",
                borderRadius: 25,
                padding: 25,
                margin: "auto",
                textAlign: "center",
                width: 250,
                height: 250,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={data.markdownRemark.frontmatter.image.publicURL}
                style={{
                  display: "inline-block",
                  maxWidth: 200,
                  maxHeight: 200,
                }}
              />
            </div>
            <h1
              style={{
                color: data.markdownRemark.frontmatter.color,
                textAlign: "center",
              }}
            >
              {data.markdownRemark.frontmatter.title}
            </h1>
            <Ast className="post-content" ast={data.markdownRemark.htmlAst} />

            <p>
              Learn{" "}
              <a href={data.markdownRemark.frontmatter.docs}>
                How to connect to {data.markdownRemark.frontmatter.title}
              </a>{" "}
              in just a few minutes.
            </p>

            <p>
              Looking for code examples?{" "}
              <a href={data.markdownRemark.frontmatter.docs}>Read the docs</a>
            </p>

            <p>
              Ready to{" "}
              <a href="http://app.adnerf.com/user/signup">
                start building with {data.markdownRemark.frontmatter.title}
              </a>
            </p>
          </div>
        </main>

        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export const PostFull = css`
  position: relative;
  z-index: 50;
`;

export const NoImage = css`
  .post-full-content {
    padding-top: 0;
  }

  .post-full-content:before,
  .post-full-content:after {
    display: none;
  }
`;

export const PostFullHeader = styled.header`
  position: relative;
  margin: 0 auto;
  padding: 70px 170px 50px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  @media (max-width: 1170px) {
    padding: 60px 11vw 50px;
  }

  @media (max-width: 800px) {
    padding-right: 5vw;
    padding-left: 5vw;
  }

  @media (max-width: 500px) {
    padding: 20px 0 35px;
  }
`;

export const PostFullTitle = styled.h1`
  margin: 0 0 0.2em;
  color: ${setLightness("0.05", colors.darkgrey)};
  @media (max-width: 500px) {
    margin-top: 0.2em;
    font-size: 3.3rem;
  }
`;

export const query = graphql`
  query($slug: String, $primaryTag: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      htmlAst
      excerpt
      timeToRead
      frontmatter {
        title
        userDate: date(formatString: "D MMMM YYYY")
        date
        tags
        excerpt
        color
        docs
        image {
          publicURL
        }
        author {
          id
          bio
          avatar {
            childImageSharp {
              gatsbyImageData(width: 200)
            }
          }
        }
      }
    }
    relatedPosts: allMarkdownRemark(
      filter: {
        frontmatter: { tags: { in: [$primaryTag] }, draft: { ne: true } }
      }
      limit: 5
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          timeToRead
          excerpt
          frontmatter {
            title
            date
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

export default FeatureTemplate;
